<template>
  <b10-base>
    <b10-toolbar :title="title">
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content :padding="3">
      <v-alert
        v-if="parteTrabajoMatsist.articulo && articulo.articulo"
        type="info"
        :value="true"
      >
        Sustituir
        <strong>{{ parteTrabajoMatsist.articulo.codigo }} -
          {{ parteTrabajoMatsist.articulo.descripcion }}</strong>
        con ficha técnica:
        <ul
          v-if="fichaTecnica.length"
          class="my-4"
        >
          <li
            v-for="(valorFicha, key) in fichaTecnica"
            :key="key"
          >
            {{ valorFicha }}
          </li>
        </ul>
        <span
          v-if="
            parteTrabajoMatsist.articulo.idarticulo !==
              articulo.articulo.idarticulo
          "
        >
          por artículo
          <strong>{{ articulo.articulo.codigo }} -
            {{ articulo.articulo.descripcion }}</strong>
        </span>
      </v-alert>
      <v-form
        ref="form"
        lazy-validation
      >
        <lparte-trabajo-material-afectado-sustitucion-form
          v-if="parteTrabajoMatsist && parteTrabajoMatsist.orden_trabajo && parteTrabajoMatsist.cliente"
          :id="null"
          :idttarifa="parteTrabajoMatsist.orden_trabajo.idttarifa"
          :idbanco-precio="parteTrabajoMatsist.orden_trabajo.idbanco_precio"
          :idcliente="parteTrabajoMatsist.cliente.idcliente"
          :idarticulo="routeParams.idarticulo"
          :has-perm-ver-tarifas="hasViewPerm(permissions.articulos.verTarifas)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LparteTrabajoMaterialAfectadoSustitucionForm from './components/LparteTrabajoMaterialAfectadoSustitucionForm'
import Data from './LparteTrabajoMaterialAfectadoSustitucionData'
import { get } from 'vuex-pathify'

export default {
  components: {
    LparteTrabajoMaterialAfectadoSustitucionForm,
  },
  mixins: [formPageMixin],
  data() {
    return {
      parteTrabajoMatsist: {},
      articulo: {},
      fichaTecnica: [],
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData(formData) {
      this.formData = formData
    },
    async loadPage() {
      this.title = 'Sustituir material'
      this.articulo = await Data.rowArticulo(this, this.routeParams.idarticulo)
      this.parteTrabajoMatsist = await Data.rowParteTrabajoMatsist(
        this,
        this.routeParams.idparte_trabajo_matsist
      )
      this.fichaTecnica = await this.$offline.materialSistema.resumenFichaTecnica(
        this.parteTrabajoMatsist
      )
    },
    async submitForm() {
      const lparteTrabajo = await Data.insert(
        this,
        this.formData,
        this.routeParams.idparte_trabajo_matsist,
        this.routeParams.idaccion_ejec,
        this.usuarioIdtecnico,
        this.routeParams.idparte_trabajo,
        this.routeParams.idarticulo
      )
      this.$appRouter.replace({
        name: "offline__lparte-trabajo-edit",
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idlparte_trabajo: lparteTrabajo.idlparteTrabajo,
        },
      })
    },
  },
}
</script>
